import * as React from 'react'

const Warmup = () => {  
  return (
    <>
      <div>warmup</div>
    </>
  )
}

export default Warmup